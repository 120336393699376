export default {
  //Landing Page
  hero_title: 'Bauen Sie Ihre persönliche Immobilienmarke online auf',
  hero_description:
    'Gewinnen Sie in weniger als 2 Minuten mehr Immobilienkäufer und -verkäufer online.',
  claim_username_cta: 'Benutzernamen beanspruchen',

  social_media_feature_title:
    'Verlinken Sie Ihre Instagram-, TikTok-, LinkedIn- oder andere Biografien',
  social_media_feature_description:
    'immotr.ee ermöglicht es Erstellern, jeden Link in eine Chance zu verwandeln',
  get_started_cta: 'Kostenlos starten',
  conversion_feature_title: 'Wandeln Sie Ihren Social-Media-Traffic in Leads um',
  conversion_feature_description:
    'immotr.ee konvertiert 2-3 Mal so gut wie herkömmliche Landingpages',
  listing_showcase_feature_title: 'Präsentieren Sie Ihre aktuellen Angebote',
  listing_showcase_feature_description:
    'Fügen Sie Ihre Top-Angebote und Bewertungen zu Ihrem Profil hinzu, um Käufer durch Social-Media-Traffic zu gewinnen.',
  automation_feature_title: 'Einfache Automatisierungen, um Zeit zu sparen',
  automation_feature_description:
    'Richten Sie automatische Antworten ein, um schnell und effizient an Verkäuferanfragen oder Immobilienanfragen zu arbeiten.',
  about_nav: 'Über uns',
  features_nav: 'Funktionen',
  why_choose_immotree: 'Warum Immotree gegenüber Standardlösungen wählen?',
  join_100_real_estate_agents_across_germany:
    'Schließen Sie sich 100+ Immobilienmaklern in ganz Deutschland an.',
  we_help_you_grow_your_business: 'Wir helfen Ihnen, Ihr Immobiliengeschäft auszubauen',
  proplinkio_app_screenshot: 'Screenshot der Proplinkio-App',
  tailored_for_real_estate_agents: 'Maßgeschneidert für Immobilienmakler',
  tailored_description:
    'Im Gegensatz zu generischen Funnel-Buildern ist Proplink.io speziell für Immobilienmakler entwickelt und stellt sicher, dass alle Funktionen und Tools für Ihre Branche relevant sind.',
  quick_and_easy_setup: 'Schnelle und einfache Einrichtung',
  quick_setup_description:
    'Richten Sie Ihre professionelle Micro-Site in kürzester Zeit ein, mit minimalem technischem Wissen.',
  comprehensive: 'Umfassend',
  comprehensive_description:
    'Von der CRM-Integration über integrierte Funnels bis hin zur Videoeinbettung bietet Proplink.io alle Tools, die Sie benötigen, um Ihre persönliche Marke zu stärken.',
  social_proof_logos: 'Social Proof Logos',
  feature: 'Funktion',
  funnel_builders: 'Funnel-Builder',
  custom_software: 'Maßgeschneiderte Software von Agentur',
  professional_micro_site: 'Professionelle Micro-Site',
  brand_customization: 'Markenanpassung',
  visitor_analytics: 'Besucheranalyse',
  crm_integration: 'CRM-Integration',
  built_in_funnels: 'Integrierte Funnels',
  professional_reviews: 'Professionelle Bewertungen',
  video_embedding: 'Video-Einbettung',
  agent_to_agent_referrals: 'Empfehlungen von Agent zu Agent',
  easy_to_setup: 'Einfach einzurichten',
  maintenance: 'Wartung',
  price: 'Preis',
  immotree_price: '<99€/Monat nach Testphase',
  funnel_builders_price: '€65/Monat bis €199/Monat',
  custom_software_price: '€5.000 bis €10.000',
  start_your_journey_now: 'Starten Sie Ihre Reise jetzt',
  username_placeholder: 'IhrBenutzername',

  reserve_your_name: 'Reservieren Sie Ihren immotree',
  reserve_your_name_description:
    'Erhalten Sie Zugriff auf Ihre persönliche Immobilienmarke und beginnen Sie noch heute mit dem Aufbau Ihres Online-Profils.',
  reserve_your_name_cta: 'Benutzernamen reservieren',
  //End of Landing Page
  //pricing.tsx --------------
  pricing_plans_title: 'Preispläne für Teams aller Größen',
  pricing_plans_description:
    'Wählen Sie einen erschwinglichen Plan, der mit den besten Funktionen ausgestattet ist, um Ihr Publikum zu engagieren, Kundentreue zu schaffen und den Verkauf zu steigern.',
  payment_frequency: 'Zahlungshäufigkeit',
  monthly: 'Monatlich',
  annually: 'Jährlich',
  '/month': '/Monat',
  '/year': '/Jahr',
  starter_plan: 'Einsteiger',
  plus_plan: 'Plus',
  business_plan: 'Business',
  starter_plan_description:
    'Ein Plan, der perfekt für den Einstieg mit Proplink.io geeignet ist.',
  plus_plan_description:
    'Ein Plan, der mehr Funktionen hinzufügt, um Ihnen beim Wachstum zu helfen.',
  business_plan_description:
    'Ein Plan, der noch mehr Funktionen hinzufügt, um Ihnen zum Erfolg zu verhelfen.',
  free_microsite: 'Kostenlose Microsite',
  custom_branding: 'Individuelles Branding',
  analytics_dashboard: 'Analytics-Dashboard',
  lead_widgets: 'Lead-Widgets',
  business_support: '24/7 Business-Support',
  proplink_marketing_pixel: 'Proplink Marketing-Pixel',
  unlimited_seller_leads: 'Unbegrenzte Verkäufer-Leads pro Monat',
  most_popular: 'Am beliebtesten',
  buy_plan: 'Plan kaufen',

  //pricing.tsx --------------
  dashboard_title: 'Dashboard',
  start_now_cta: 'Jetzt starten',
  dashboard_description: 'Verwalten Sie Ihre öffentliche Seite',
  dashboard_getstarted: 'Loslegen',
  dashboard_getstarted_description: 'Erstellen Sie Ihre öffentliche Seite',

  submitting_data: 'Daten werden übermittelt...',
  dasboard_bio: 'Biografie',
  dashboard_bio_description: 'Erzählen Sie uns von sich',
  dashboard_bio_placeholder: 'Schreiben Sie eine kurze Biografie über sich',
  preview: 'Vorschau',
  dashboard_profile_analytics: 'Analysen Pixel',
  dashboard_profile_save: 'Speichern',
  dashboard_profile_bg_color: 'Hintergrundfarbe',
  dashboard_profile_publish: 'Veröffentlichen',
  dashboard_profile_social_links: 'Social Media Links',
  dasbhboard_profile_customer_reviews: 'Kundenbewertungen',
  dashboard_profile_recent_listing: 'Aktuelle Angebote',
  dashboard_profile_text_color: 'Textfarbe',
  is_agent: ' Bist du ein Immobilienmakler?',
  you_have_more_leads_than_allowed:
    'Sie haben mehr Leads als Ihr aktueller Plan erlaubt. Bitte aktualisieren Sie Ihren Plan, um fortzufahren.',
  listings_edit_button: 'Bearbeiten',
  listings_delete_button: 'Löschen',

  leads_chart_label: 'Leads Analyse',
  listings_add_button: 'Neues Angebot erstellen',
  listings_update_button: 'Immobilie aktualisieren',
  form_data_privacy:
    'Es gelten unsereAllgemeinen Geschäftsbedingungen.In unsererDatenschutzerklärungfinden Sie Informationen darüber, wie wir Ihre personenbezogenen Daten verarbeiten.',
  reviews_title: 'Bewertungen',
  add_review: 'Bewertung hinzufügen',
  edit: 'Bearbeiten',
  edit_review: 'Bewertung bearbeiten',
  create_review: 'Bewertung erstellen',
  delete: 'Löschen',
  confirm_deletion: 'Löschung bestätigen',
  delete_review_confirmation:
    'Sind Sie sicher, dass Sie die Bewertung "{title}" von {reviewer} löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
  cancel: 'Abbrechen',
  deleting: 'Wird gelöscht...',
  title: 'Titel',
  reviewer: 'Bewerter',
  description: 'Beschreibung',
  rating: 'Bewertung',
  avatar: 'Avatar',
  select_rating: 'Bewertung auswählen',
  out_of_5: 'von 5',
  update_review: 'Bewertung aktualisieren',
  creating: 'Wird erstellt...',
  updating: 'Wird aktualisiert...',
  enter_review_title: 'Geben Sie den Titel der Bewertung ein',
  enter_reviewer_full_name: 'Geben Sie den vollständigen Namen des Bewerters ein',
  enter_review_description: 'Geben Sie die Beschreibung der Bewertung ein',
  listings_intro: 'Zeigen Sie Ihre Immobilien und erhalten Sie mehr Leads',
  fantastic: 'Fantastisch!',
  next_step: 'Nächster Schritt',
  reviews_intro:
    'Zeigen Sie Ihre Bewertungen und bauen Sie Vertrauen zu Ihren Kunden auf',
  great: 'Großartig!',
  fill_info_prompt: 'Lass uns einige Informationen über dich eintragen.',
  avatar_size_error: 'Die Dateigröße des Avatar-Bildes muss kleiner als 5 MB sein.',
  enter_full_name: 'Fullständigen Namen',
  enter_subheading: 'Unterüberschrift',
  bio: 'Biografie',
  phone: 'Telefon',
  email: 'E-Mail',
  continue: 'Weiter',
  update_info_anytime:
    'Du kannst diese Informationen jederzeit in deinen Kontoeinstellungen aktualisieren.',

  currency: 'Währung',
  select_currency: 'Währung auswählen',
  purpose: 'Zweck',
  select_purpose: 'Zweck auswählen',
  rent: 'Miete',
  sale: 'Verkauf',
  interval: 'Intervall',
  select_interval: 'Intervall auswählen',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  yearly: 'Jährlich',
  type: 'Typ',
  select_type: 'Typ auswählen',
  apartment: 'Wohnung',
  house: 'Haus',
  condo: 'Eigentumswohnung',
  townhouse: 'Reihenhaus',
  location: 'Standort',
  size: 'Größe',
  units: 'Einheiten',
  select_units: 'Einheiten auswählen',
  sqft: 'sq ft',
  sqm: 'qm',
  bedrooms: 'Schlafzimmer',
  bathrooms: 'Badezimmer',
  current_images: 'Aktuelle Bilder',
  property_image: 'Immobilienbild',
  marked_for_removal: 'Zum Entfernen markiert',
  add_new_images: 'Neue Bilder hinzufügen',
  new_images_to_upload: 'Neue Bilder zum Hochladen',
  new_upload: 'Neuer Upload',
  dashboard_bio: 'Biografie',
  your_avatar: 'Dein Avatar',
  avatar_description: 'Dies ist dein Avatar. Er wird auf deinem Profil angezeigt.',
  avatar_upload_instruction:
    'Klicke auf den Avatar, um einen benutzerdefinierten aus deinen Dateien hochzuladen.',
  reset: 'Zurücksetzen',
  your_username: 'Dein Benutzername',
  username_description:
    'Dies ist dein Benutzername. Er wird auf deinem Profil angezeigt.',
  username: 'Benutzername',
  username_character_limit: 'Bitte verwende maximal 32 Zeichen.',
  save: 'Speichern',
  delete_account: 'Konto löschen',
  delete_account_description:
    'Lösche dein Proplist-Konto dauerhaft, einschließlich aller deiner Projekte, Links und deren Statistiken.',
  delete_account_warning:
    'Diese Aktion kann nicht rückgängig gemacht werden, gehe vorsichtig vor.',
  are_you_sure: 'Bist du sicher?',

  immotree_billing: 'Immotree Abrechnung',
  manage_plans_and_billings: 'Hier können Sie Pläne und Abrechnungen verwalten',
  plan: 'Plan',
  current_plan_status: 'Sie nutzen derzeit den {{planName}}-Plan.',
  free: 'Kostenlos',
  month: 'Monat',
  year: 'Jahr',
  expires: 'Läuft ab',
  renews: 'Verlängert sich',
  on: 'am',
  upgrade_to_pro: 'Auf PRO upgraden',
  manage_subscription: 'Abonnement verwalten',
  update_payment_info:
    'Aktualisieren Sie Ihre Zahlungsmethode, Rechnungsadresse und mehr.',
  stripe_redirect_notice: 'Sie werden zum Stripe Kundenportal weitergeleitet.',
  manage: 'Verwalten',

  update_property: 'Immobilie aktualisieren',
  create_property: 'Immobilie erstellen',
  delete_property_confirmation:
    'Sind Sie sicher, dass Sie diese Immobilie löschen möchten?',
  edit_property: 'Immobilie bearbeiten',
  listings: 'Angebote',
  create_new_property: 'Neue Immobilie erstellen',
  property_details: '{{bedrooms}} Zimmer | {{bathrooms}} Bad | {{size}} {{units}}',

  leads_view_button: 'Lead anzeigen',

  settings_title: 'Einstellungen',
  settings_description: 'Verwalten Sie Ihre Kontoeinstellungen',

  settings_avatar_title: 'Ihr Avatar',
  settings_avatar_description: 'Dies ist Ihr Avatar. Er wird in Ihrem Profil angezeigt',
  settings_avatar_change_instruction:
    'Klicken Sie auf den Avatar, um einen benutzerdefinierten aus Ihren Dateien hochzuladen.',

  settings_username_title: 'Ihr Benutzername',
  settings_username_description:
    'Dies ist Ihr Benutzername. Er wird in Ihrem Profil angezeigt',
  settings_username_constraint: 'Bitte verwenden Sie maximal 32 Zeichen.',
  settings_username_save_button: 'Speichern',

  settings_account_delete_title: 'Konto löschen',
  settings_account_delete_description:
    'Löschen Sie Ihr Proplist-Konto dauerhaft, einschließlich aller Ihrer Projekte, Links und deren Statistiken.',
  settings_account_delete_button: 'Konto löschen',

  settings_nav_general: 'Allgemein',
  settings_nav_billing: 'Abrechnung',

  settings_billing_title: 'Abrechnung',
  settings_billing_description: 'Verwalten Sie die Abrechnung und Ihren Abonnementplan.',

  settings_billing_plan_title: 'Proplink Abrechnung',
  settings_billing_plan_description: 'Hier können Sie Pläne und Abrechnungen verwalten',
  settings_billing_plan_current: 'Plan',
  settings_billing_plan_upgrade: 'Auf Pro upgraden',
  settings_billing_manage_button: 'Verwalten',
  settings_billing_manage_title: 'Abonnement verwalten',
  settings_billing_manage_description:
    'Aktualisieren Sie Ihre Zahlungsmethode, Rechnungsadresse und mehr.',
  settings_billing_manage_redirect_hint:
    'Sie werden zum Stripe-Kundenportal weitergeleitet.',

  lead_widget_qs_property_type: 'Welche Immobilie möchten Sie bewerten?',
  lead_widget_land: 'Grundstück',
  lead_widget_house: 'Haus',
  lead_widget_apartment: 'Wohnung',
  lead_widget_commercial_space: 'Gewerbe',
  lead_widget_qs_property_size: 'Wie groß ist die Immobilie?',
  label_size: 'Größe',
  placeholder_size: 'Größe eingeben',
  button_next: 'Weiter',
  button_previous: 'Zurück',
  lead_widget_qs_bedroom: 'Wie viele Schlafzimmer hat die Immobilie?',
  label_slider: 'Anzahl der Schlafzimmer',
  lead_widget_qs_build_year: 'Wann wurde die Immobilie gebaut?',
  lead_widget_build_year_label: 'Baujahr',
  lead_widget_qs_property_vacancy: 'Ist die Immobilie derzeit bewohnt?',
  lead_widget_qs_property_vacancy_rented: 'Vermietet',
  lead_widget_qs_property_vacancy_vacant: 'Leer stehend',
  lead_widget_qs_property_condition: 'In welchem Zustand ist die Immobilie?',
  lead_widget_conditon_new: 'Neu / Neuwertig',
  lead_widget_conditon_renovation_needed: 'Renovierungsbedürftig',
  lead_widget_conditon_well_maintained: 'Gut erhalten',
  lead_widget_qs_property_transaction: 'Wofür benötigen Sie den Immobilienwert?',
  lead_widget_qs_property_transaction_sale: 'Verkaufen',
  lead_widget_qs_property_transaction_buy: 'Kaufen',
  lead_widget_qs_property_transaction_rent: 'Sonsiges',
  lead_widget_qs_property_timeframe: 'Was ist Ihr Zeitrahmen?',
  lead_widget_qs_property_timeframe_3months: 'Innerhalb von 3 Monaten',
  lead_widget_qs_property_timeframe_6months: 'Innerhalb von 6 Monaten',
  lead_widget_qs_property_timeframe_6_12months: '6-12 Monate',
  lead_widget_qs_property_timeframe_12months_plus: 'Mehr als 12 Monate',
  lead_widget_qs_property_occupation: 'Wie ist der aktuelle Belegungsstatus?',
  lead_widget_qs_property_occupation_owner: 'Selbst bewohnt',
  lead_widget_qs_property_occupation_rented: 'Vermietet',
  lead_widget_qs_postalCode_heading: 'Wie lautet die Postleitzahl der Immobilie?',
  lead_widget_postalCode_label: 'Postleitzahl',
  lead_widget_personal_info: 'An wen soll die professionelle Immobilienbewertung gehen?',
  lead_widget_personal_info_description: 'Bitte geben Sie Ihre persönlichen Daten an',

  page_views: 'Seitenaufrufe',
  page_views_description: 'Die Anzahl der Seitenaufrufe auf Ihrer öffentlichen Seite',
  conversion_rate: 'Konversionsrate',
  pageview_vs_funnel_start: 'Seitenaufrufe vs Funnel Star',
  funnel_complete_vs_funnel_start: 'Funnel Complete vs Funnel Star',
  funnel_chart_label: 'Funnel Analyse',
  funnel_start: 'Funnel Start',
  funnel_complete: 'Funnel Complete',
  public_profile_not_found:
    'Hier ist noch nichts! Dies könnte Ihr öffentliches Profil sein',
  public_profile_not_found_cta: 'Immotree-Seite sichern!',
  title_label: 'Anrede',
  select_value_placeholder: 'Wählen Sie eine Anrede',
  title_mr: 'Herr',
  title_mrs: 'Frau',

  full_name_label: 'Vollständiger Name',
  title_other: 'Andere',
  firstName_label: 'Vorname',
  firstName_placeholder: 'Vorname',
  lastName_label: 'Nachname',
  city: 'Stadt',
  lastName_placeholder: 'Nachname',
  lead_widget_qs_phone_number: 'Wie lautet Ihre Telefonnummer?',
  lead_widget_phone_number_label: 'Telefonnummer',
  lead_widget_qs_email: 'Wie lautet Ihre E-Mail-Adresse?',
  lead_widget_email_label: 'E-Mail-Adresse',
  lead_widget_thank_you: 'Vielen Dank!',
  lead_widget_thank_you_description:
    'Wir haben Ihre Informationen erhalten und werden uns bald bei Ihnen melden.',
  public_profile_property_valuation_header: 'Kostenlose Immobilienbewertung',
  dashboard_profile_customer_reviews: 'Kundenbewertungen',
  dashboard_personal_accounti_label: 'Persönliches Konto',
  settings_billing_plan_upgrade_button: 'Auf Pro upgraden',
  dashboard_nav_settings: 'Einstellungen',
  dashboard_nav_language: 'Sprache',
  dashboard_nav_logout: 'Abmelden',
  dashboard_nav_admin: 'Admin',
  dashboard_nav_dashboard: 'Dashboard',
  dashboard_nav_listings: 'Anzeigen',
  dashboard_nav_reviews: 'Bewertungen',
  dashboard_nav_leads: 'Leads',
  dashboard_nav_analytics: 'Analysen',
  dashboard_nav_billing: 'Abrechnung',
}
